@use '@sats-group/ui-lib/tokens/spacing';
@use '@sats-group/ui-lib/tokens/lightmode';

.leads-and-clients {
  $breakpoint: 700px;

  &__header {
    background-color: lightmode.$primary;
    padding: spacing.$l 0;
    z-index: 10;
  }

  &__title {
    color: lightmode.$on-primary;
    margin-bottom: spacing.$m;
  }

  &__form {
    display: flex;
    flex-direction: column;

    &:not(:last-child) {
      margin-bottom: spacing.$l;

      @media (min-width: 950px) {
        margin-bottom: 0;
      }
    }
  }

  &__form-title {
    color: lightmode.$on-primary;
    margin-bottom: spacing.$xxs;
  }

  &__select {
    @media (min-width: $breakpoint) {
      width: 305px;
    }
  }

  &__helpers {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    margin-top: spacing.$l;

    >* {
      margin-bottom: spacing.$s;
    }
  }

  &__sort {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: spacing.$m;
  }

  &__spinner {
    position: absolute;
    z-index: 2;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    background-color: rgba(white, 0.7);
  }

  &__members {
    display: flex;
    align-items: flex-start;
    gap: spacing.$l;
    margin-top: spacing.$m;
    margin-bottom: spacing.$xl;
    position: relative;
  }

  &__member-list {
    color: lightmode.$on-background-primary;
    list-style-type: none;
    margin: 0;
    padding: 0;
    flex-grow: 1;
  }

  &__pagination {
    margin: spacing.$xl 0;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: spacing.$s spacing.$xxl;
    flex-wrap: wrap;
    padding: 0 spacing.$l;
  }

  &__empty-list {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: spacing.$xl;
  }

  &__empty-list-inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: spacing.$xs;
  }

  &__search {
    background-color: lightmode.$clean;
    padding: spacing.$m 0;
    display: flex;
    width: 100%;
  }
}
